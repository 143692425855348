import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './WalletManagementPage.css';

const WalletManagementPage = () => {
  const [coins, setCoins] = useState([]);
  const [newCoin, setNewCoin] = useState({ name: '', symbol: '', icon: '' });
  const [editingCoinId, setEditingCoinId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCoins();
  }, []);

  const fetchCoins = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://app.sunlion.meme/api/get_coins');
      setCoins(response.data);
    } catch (error) {
      console.error('Error fetching coins:', error);
      setError('Failed to fetch coins. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCoin({ ...newCoin, [name]: value });
  };

  const validateForm = () => {
    if (!newCoin.name || !newCoin.symbol || !newCoin.icon) {
      setError('Please fill in all fields.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setError(null);
    try {
      if (editingCoinId) {
        await axios.put(`https://app.sunlion.meme/api/update_coin/${editingCoinId}`, newCoin);
        alert('Coin updated successfully!');
      } else {
        await axios.post('https://app.sunlion.meme/api/add_coin', newCoin);
        alert('Coin added successfully!');
      }
      fetchCoins();
      setNewCoin({ name: '', symbol: '', icon: '' });
      setEditingCoinId(null);
    } catch (error) {
      console.error('Error adding/updating coin:', error);
      setError(`Failed to ${editingCoinId ? 'update' : 'add'} coin. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (coin) => {
    setNewCoin({ name: coin.name, symbol: coin.symbol, icon: coin.icon });
    setEditingCoinId(coin._id);
  };

  const handleDelete = async (coinId) => {
    if (!window.confirm('Are you sure you want to delete this coin?')) return;

    setIsLoading(true);
    setError(null);
    try {
      await axios.delete(`https://app.sunlion.meme/api/delete_coin/${coinId}`);
      alert('Coin deleted successfully!');
      fetchCoins();
    } catch (error) {
      console.error('Error deleting coin:', error);
      setError('Failed to delete coin. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="wallet-management-container">
      <h1>Wallet Management</h1>
      <form onSubmit={handleSubmit} className="coin-form">
        <h2>{editingCoinId ? 'Edit Coin' : 'Add New Coin'}</h2>
        <div className="form-group">
          <label htmlFor="name">Coin Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={newCoin.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="symbol">Coin Symbol:</label>
          <input
            type="text"
            id="symbol"
            name="symbol"
            value={newCoin.symbol}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="icon">Coin Icon URL:</label>
          <input
            type="url"
            id="icon"
            name="icon"
            value={newCoin.icon}
            onChange={handleInputChange}
            required
          />
        </div>
        {newCoin.icon && (
          <div className="image-preview">
            <img src={newCoin.icon} alt="Coin Icon Preview" />
          </div>
        )}
        {error && <div className="error-message">{error}</div>}
        <div className="form-actions">
          <button type="submit" className="btn-primary" disabled={isLoading}>
            {isLoading ? 'Processing...' : (editingCoinId ? 'Update Coin' : 'Add Coin')}
          </button>
          {editingCoinId && (
            <button
              type="button"
              className="btn-secondary"
              onClick={() => {
                setNewCoin({ name: '', symbol: '', icon: '' });
                setEditingCoinId(null);
                setError(null);
              }}
            >
              Cancel Edit
            </button>
          )}
        </div>
      </form>
      <div className="coin-list">
        <h2>Existing Coins</h2>
        {isLoading && <p>Loading coins...</p>}
        {coins.map((coin) => (
          <div key={coin._id} className="coin-item">
            <div className="coin-content">
              <div className="coin-icon">
                <img
                  src={coin.icon}
                  alt={coin.name}
                  onError={(e) => {
                    e.target.src = '/fallback-image.png';
                  }}
                />
              </div>
              <div className="coin-details">
                <h3>{coin.name}</h3>
                <p>Symbol: {coin.symbol}</p>
              </div>
            </div>
            <div className="coin-actions">
              <button onClick={() => handleEdit(coin)} className="btn-secondary">Edit</button>
              <button onClick={() => handleDelete(coin._id)} className="btn-secondary">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletManagementPage;