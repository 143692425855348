import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminWithdrawPage.css';

const AdminWithdrawPage = () => {
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPendingWithdrawals();
  }, []);

  const fetchPendingWithdrawals = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://app.sunlion.meme/api/pending_withdrawals');
      setPendingWithdrawals(response.data.withdrawals);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching pending withdrawals:', error);
      setError('Failed to fetch pending withdrawals. Please try again.');
      setLoading(false);
    }
  };

  const handleWithdrawalAction = async (withdrawalId, action) => {
    try {
      await axios.post('https://app.sunlion.meme/api/process_withdrawal', {
        withdrawal_id: withdrawalId,  // Changed from withdrawalId to withdrawal_id
        action
      });
      fetchPendingWithdrawals();
    } catch (error) {
      console.error('Error processing withdrawal:', error);
      setError(`Failed to ${action} withdrawal. Please try again.`);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="admin-withdraw-page">
      <h1>Admin Withdrawal Management</h1>
      {pendingWithdrawals.length === 0 ? (
        <div className="no-withdrawals">No pending withdrawals at this time.</div>
      ) : (
        <table className="admin-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Amount</th>
              <th>Coin</th>
              <th>Address</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pendingWithdrawals.map((withdrawal) => (
              <tr key={withdrawal.withdrawal_id}>
                <td>{withdrawal.username}</td>
                <td>{withdrawal.amount}</td>
                <td>{withdrawal.coin}</td>
                <td>{withdrawal.address}</td>
                <td>
                  <div className="action-buttons">
                    <button
                      className="approve-btn"
                      onClick={() => handleWithdrawalAction(withdrawal.withdrawal_id, 'approve')}
                    >
                      Approve
                    </button>
                    <button
                      className="decline-btn"
                      onClick={() => handleWithdrawalAction(withdrawal.withdrawal_id, 'decline')}
                    >
                      Decline
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminWithdrawPage;