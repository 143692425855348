import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TaskAdmin.css";

const TaskAdmin = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({
    description: "",
    link: "",
    score: "",
    icon: "",
    recurInterval: "",
    rewardCoin: ""
  });
  const [coins, setCoins] = useState([]);
  const [newCoin, setNewCoin] = useState({ name: "", symbol: "", icon: "" });
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAddCoinModal, setShowAddCoinModal] = useState(false);

  useEffect(() => {
    fetchTasks();
    fetchCoins();
  }, []);

  const fetchTasks = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get("https://app.sunlion.meme/api/get_tasks");
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setError("Failed to fetch tasks. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCoins = async () => {
    try {
      const response = await axios.get("https://app.sunlion.meme/api/get_coins");
      setCoins(response.data);
    } catch (error) {
      console.error("Error fetching coins:", error);
      setError("Failed to fetch coins. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "rewardCoin" && value === "add_new") {
      setShowAddCoinModal(true);
    } else {
      setNewTask({ ...newTask, [name]: value });
    }
  };

  const handleCoinInputChange = (e) => {
    const { name, value } = e.target;
    setNewCoin({ ...newCoin, [name]: value });
  };

  const validateForm = () => {
    if (!newTask.description || !newTask.link || !newTask.score || !newTask.icon || !newTask.rewardCoin) {
      setError("Please fill in all required fields.");
      return false;
    }
    if (isNaN(Number(newTask.score)) || Number(newTask.score) <= 0) {
      setError("Score must be a positive number.");
      return false;
    }
    if (newTask.recurInterval && (isNaN(Number(newTask.recurInterval)) || Number(newTask.recurInterval) <= 0)) {
      setError("Recur interval must be a positive number.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setError(null);
    try {
      const taskData = {
        ...newTask,
        score: Number(newTask.score),
        recurInterval: newTask.recurInterval ? Number(newTask.recurInterval) : undefined
      };

      if (editingTaskId) {
        const response = await axios.put(`https://app.sunlion.meme/api/update_task/${editingTaskId}`, taskData);
        alert("Task updated successfully!");
      } else {
        const response = await axios.post("https://app.sunlion.meme/api/create_task", taskData);
        alert(response.data.message || "Task added successfully and notifications sent!");
      }
      fetchTasks();
      setNewTask({ description: "", link: "", score: "", icon: "", recurInterval: "", rewardCoin: "" });
      setEditingTaskId(null);
    } catch (error) {
      console.error("Error adding/updating task:", error);
      setError(
        `An error occurred while ${editingTaskId ? 'updating' : 'adding'} the task: ${error.response?.data?.error || error.message}. 
        Status: ${error.response?.status}. 
        Please check the console for more details and try again.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCoin = async () => {
    if (!newCoin.name || !newCoin.symbol || !newCoin.icon) {
      setError("Please fill in all coin fields.");
      return;
    }

    try {
      const response = await axios.post("https://app.sunlion.meme/api/add_coin", newCoin);
      alert("Coin added successfully!");
      setNewCoin({ name: "", symbol: "", icon: "" });
      setShowAddCoinModal(false);
      fetchCoins();
    } catch (error) {
      console.error("Error adding coin:", error);
      setError("Failed to add coin. Please try again.");
    }
  };

  const handleEdit = (task) => {
    setNewTask({
      description: task.description,
      link: task.link,
      score: task.score.toString(),
      icon: task.icon,
      recurInterval: task.recurInterval ? task.recurInterval.toString() : "",
      rewardCoin: task.rewardCoin
    });
    setEditingTaskId(task._id);
  };

  const handleDelete = async (taskId) => {
    if (!window.confirm("Are you sure you want to delete this task?")) return;

    setIsLoading(true);
    setError(null);
    try {
      await axios.delete(`https://app.sunlion.meme/api/delete_task/${taskId}`);
      alert("Task deleted successfully!");
      fetchTasks();
    } catch (error) {
      console.error("Error deleting task:", error);
      setError("Failed to delete task. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="task-admin-container">
      <h1>Task Administration</h1>
      <form onSubmit={handleSubmit} className="task-form">
        <h2>{editingTaskId ? "Edit Task" : "Add New Task"}</h2>
        <div className="form-group">
          <label htmlFor="description">Task Description:</label>
          <input
            type="text"
            id="description"
            name="description"
            value={newTask.description}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="link">Task Link:</label>
          <input
            type="url"
            id="link"
            name="link"
            value={newTask.link}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="score">Task Score:</label>
          <input
            type="number"
            id="score"
            name="score"
            value={newTask.score}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="icon">Task Icon URL:</label>
          <input
            type="url"
            id="icon"
            name="icon"
            value={newTask.icon}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="rewardCoin">Reward Coin:</label>
          <select
            id="rewardCoin"
            name="rewardCoin"
            value={newTask.rewardCoin}
            onChange={handleInputChange}
            required
          >
            <option value="">Select a coin</option>
            {coins.map((coin) => (
              <option key={coin._id} value={coin.symbol}>
                {coin.name} ({coin.symbol})
              </option>
            ))}
            <option value="add_new">+ Add New Coin</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="recurInterval">Recur Interval (hours, optional):</label>
          <input
            type="number"
            id="recurInterval"
            name="recurInterval"
            value={newTask.recurInterval}
            onChange={handleInputChange}
          />
        </div>
        {newTask.icon && (
          <div className="image-preview">
            <img src={newTask.icon} alt="Task Icon Preview" />
          </div>
        )}
        {error && <div className="error-message">{error}</div>}
        <div className="form-actions">
          <button type="submit" className="btn-primary" disabled={isLoading}>
            {isLoading ? "Processing..." : (editingTaskId ? "Update Task" : "Add Task")}
          </button>
          {editingTaskId && (
            <button
              type="button"
              className="btn-secondary"
              onClick={() => {
                setNewTask({ description: "", link: "", score: "", icon: "", recurInterval: "", rewardCoin: "" });
                setEditingTaskId(null);
                setError(null);
              }}
            >
              Cancel Edit
            </button>
          )}
        </div>
      </form>
      <div className="task-list">
        <h2>Existing Tasks</h2>
        {isLoading && <p>Loading tasks...</p>}
        {tasks.map((task) => (
          <div key={task._id} className="task-item">
            <div className="task-content">
              <div className="task-icon">
                <img
                  src={task.icon}
                  alt={task.description}
                  onError={(e) => {
                    e.target.src = '/fallback-image.png';
                  }}
                />
              </div>
              <div className="task-details">
                <h3>{task.description}</h3>
                <p>Link: {task.link}</p>
                <p>Score: {task.score} {task.rewardCoin}</p>
                {task.recurInterval && <p>Recurs every {task.recurInterval} hours</p>}
              </div>
            </div>
            <div className="task-actions">
              <button onClick={() => handleEdit(task)} className="btn-secondary">Edit</button>
              <button onClick={() => handleDelete(task._id)} className="btn-secondary">Delete</button>
            </div>
          </div>
        ))}
      </div>
      {showAddCoinModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Add New Coin</h2>
            <div className="form-group">
              <label htmlFor="coinName">Coin Name:</label>
              <input
                type="text"
                id="coinName"
                name="name"
                value={newCoin.name}
                onChange={handleCoinInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="coinSymbol">Coin Symbol:</label>
              <input
                type="text"
                id="coinSymbol"
                name="symbol"
                value={newCoin.symbol}
                onChange={handleCoinInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="coinIcon">Coin Icon URL:</label>
              <input
                type="url"
                id="coinIcon"
                name="icon"
                value={newCoin.icon}
                onChange={handleCoinInputChange}
                required
              />
            </div>
            <div className="form-actions">
              <button onClick={handleAddCoin} className="btn-primary">Add Coin</button>
              <button onClick={() => setShowAddCoinModal(false)} className="btn-secondary">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskAdmin;