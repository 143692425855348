import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './ValidateTask.css';

const API_BASE_URL = 'https://app.sunlion.meme/api';

const ValidateTask = () => {
  const [pendingTasks, setPendingTasks] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage] = useState(12);

  const fetchPendingTasks = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_BASE_URL}/get_pending_tasks`);
      console.log('Fetched pending tasks:', response.data);
      setPendingTasks(response.data);
    } catch (error) {
      console.error("Error fetching pending tasks:", error);
      setError(`Failed to fetch pending tasks. ${error.response?.data?.error || error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPendingTasks();
  }, [fetchPendingTasks]);

  const handleTaskAction = async (task, action) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/${action}_task`, {
        task_id: task._id,
        username: task.username,
        rewardCoin: task.rewardCoin
      });
      console.log(`${action} task response:`, response.data);
      setPendingTasks((prevTasks) => prevTasks.filter(t => t._id !== task._id));
      setSuccessMessage(`Task ${action === 'approve' ? 'approved' : 'rejected'} successfully for user ${task.username}. ${action === 'approve' ? 'Notification sent to user.' : ''}`);
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (error) {
      console.error(`Error ${action}ing task:`, error);
      setError(`Failed to ${action} task. ${error.response?.data?.error || error.message}`);
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleApproveTask = (task) => handleTaskAction(task, 'approve');
  const handleRejectTask = (task) => handleTaskAction(task, 'reject');

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  // Pagination logic
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = pendingTasks.slice(indexOfFirstTask, indexOfLastTask);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) {
    return <div className="loading">Loading pending tasks...</div>;
  }

  return (
    <div className="validate-task-page">
      <header className="page-header">
        <h1>Pending Tasks for Validation</h1>
        {successMessage && <div className="success-message">{successMessage}</div>}
        {error && <div className="error-message">{error}</div>}
      </header>

      {pendingTasks.length === 0 ? (
        <p className="no-tasks">No pending tasks for validation.</p>
      ) : (
        <>
          <div className="task-grid">
            {currentTasks.map((task) => (
              <TaskCard
                key={task._id}
                task={task}
                onApprove={handleApproveTask}
                onReject={handleRejectTask}
                onImageClick={openImageModal}
              />
            ))}
          </div>
          <Pagination
            tasksPerPage={tasksPerPage}
            totalTasks={pendingTasks.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </>
      )}

      {selectedImage && (
        <ImageModal
          imageUrl={selectedImage}
          onClose={closeImageModal}
        />
      )}
    </div>
  );
};

const TaskCard = ({ task, onApprove, onReject, onImageClick }) => (
  <div className="task-card">
    <h3 className="task-description">{task.taskDescription || 'No description available'}</h3>
    <p className="task-username">Submitted by: {task.username || 'Unknown user'}</p>
    <p className="task-reward">Reward: {task.score || 0} {task.rewardCoin}</p>
    <div className="evidence-container">
      {task.evidenceUrl ? (
        <img 
          src={task.evidenceUrl} 
          alt="Task Evidence" 
          className="evidence-thumbnail" 
          onClick={() => onImageClick(task.evidenceUrl)}
          onError={(e) => {
            console.error(`Failed to load image: ${task.evidenceUrl}`);
            e.target.onerror = null;
            e.target.src = '/path/to/fallback-image.png'; // Replace with actual fallback image path
          }}
        />
      ) : (
        <p>No evidence provided</p>
      )}
    </div>
    <div className="task-actions">
      <button onClick={() => onApprove(task)} className="approve-button">Approve</button>
      <button onClick={() => onReject(task)} className="reject-button">Reject</button>
    </div>
  </div>
);

const ImageModal = ({ imageUrl, onClose }) => (
  <div className="image-modal" onClick={onClose}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <img src={imageUrl} alt="Full size evidence" />
      <button className="close-modal" onClick={onClose}>&times;</button>
    </div>
  </div>
);

const Pagination = ({ tasksPerPage, totalTasks, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalTasks / tasksPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <a onClick={() => paginate(number)} href='#!' className='page-link'>
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ValidateTask;